<template>
  <div class="rule_div">
    <el-form class="rule_top" inline>
      <el-form-item>
        <el-input class="rule_top-el" v-model="queryInfo.assetSn" style="width: 200px" placeholder="资产码" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input class="rule_top-el" v-model="queryInfo.lendOrderCode" style="width: 200px" placeholder="借出单号" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-input class="rule_top-el" v-model="queryInfo.materialCode" style="width: 200px" placeholder="备件编码" clearable></el-input>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryInfo.planCategory" class="rule_top-el" placeholder="计划大类" clearable filterable>
          <el-option v-for="item in planCategoryList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-select v-model="queryInfo.chargeLineCode" class="rule_top-el" placeholder="运作产线" clearable filterable>
          <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
        </el-select>
      </el-form-item>
      <el-form-item>
        <el-button type="primary" :loading="queryLoading" @click="currentChange(1)">查询</el-button>
        <el-button type="primary" @click="showAdd">添加规则</el-button>
        <el-button type="primary" @click="exportData" :loading="exportLoading">导出</el-button>
      </el-form-item>
    </el-form>
    <div class="tip" style="margin-left: 20px;">提示：匹配优先级：资产码>借出单号>备件编码>计划大类>运作产线。</div>
    <el-table class="rule_table" :data="ruleList" border stripe>
      <el-table-column label="资产码" prop="assetSn" align="center" width="200"></el-table-column>
      <el-table-column label="借出单号" prop="lendOrderCode" align="center" width="200"></el-table-column>
      <el-table-column label="备件编码" prop="materialCode" align="center" width="100"></el-table-column>
      <el-table-column label="计划大类" prop="planCategoryName" align="center" width="130"></el-table-column>
      <el-table-column label="运作产线" prop="chargeLineName" align="center" width="130"></el-table-column>
      <el-table-column label="检验提示内容" prop="ruleText" align="center"></el-table-column>
      <el-table-column label="操作人" prop="updateUserName" align="center" width="100"></el-table-column>
      <el-table-column label="操作时间" prop="updateTime" align="center" width="150"></el-table-column>
      <el-table-column label="操作" align="center" width="150">
        <template slot-scope="scope">
          <el-button type="primary" size="mini" @click="showChange(scope.row.id)">编辑</el-button>
          <el-button type="danger" size="mini" @click="deleteRule(scope.row.id)">删除</el-button>
        </template>
      </el-table-column>
    </el-table>
    <div class="page-box">
      <el-pagination background layout="total,prev, pager, next" :page-size="pageSize" :total="total" @current-change="currentChange" :current-page.sync="queryInfo.pageNo"></el-pagination>
    </div>

    <!--添加规则对话框-->
    <el-dialog :title="dialogTitle" :visible.sync="ruleDialog" width="700px" :close-on-click-modal="false">
      <el-form :model="addInfo" label-width="200px">
        <el-form-item label="资产码：">
          <el-input placeholder="请输入资产码" v-model="addInfo.assetSn" class="form-item"></el-input>
        </el-form-item>
        <el-form-item label="借出单号：">
          <el-input placeholder="请输入借出单号" v-model="addInfo.lendOrderCode" class="form-item"></el-input>
        </el-form-item>
        <el-form-item label="备件编码：">
          <el-input placeholder="请输入备件编码" v-model="addInfo.materialCode" class="form-item"></el-input>
        </el-form-item>
        <el-form-item label="计划大类：">
          <el-select v-model="addInfo.planCategory" class="form-item" placeholder="计划大类" clearable filterable>
            <el-option v-for="item in planCategoryList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="运作产线：">
          <el-select v-model="addInfo.chargeLineCode" class="form-item" placeholder="运作产线" clearable filterable>
            <el-option v-for="item in chargeLineCodeList" :key="item.value" :label="item.name" :value="item.value"></el-option>
          </el-select>
        </el-form-item>
        <el-form-item label="检验提示：">
          <el-input placeholder="请输入检验提示" type="textarea" v-model.number="addInfo.ruleText" class="form-item"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="ruleDialog = false">取 消</el-button>
        <el-button type="primary" :loading="addLoading" @click="addRule">确 定</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
export default {
  name: "RuleManagement",
  data() {
    return {
      queryInfo: {
        lendOrderCode: '',
        materialCode: '',
        planCategory: '',
        chargeLineCode: '',
        assetSn: '',
        pageNo: 1,
      },
      ruleList: [],
      queryLoading: false,
      ruleDialog: false,
      addInfo: {
        lendOrderCode: '',
        materialCode: '',
        planCategory: '',
        chargeLineCode: '',
        assetSn: '',
        ruleText: ''
      },
      dialogTitle: '添加检验提示',
      total: 0,
      pageSize: 10,
      addLoading: false,
      exportLoading:false
    }
  },
  computed: {
    planCategoryList() {
      return this.$store.getters.getDictList('planCategory') || [];
    },
    chargeLineCodeList() {
      return this.$store.getters.getDictList('chargeLineCode') || [];
    },
  },
  created() {
    this.search();
  },
  methods: {
    async search() {
      this.queryLoading = true
      let {data: res} = await this.$axios.post('/busTipRule/selectList', this.queryInfo);
      this.queryLoading = false
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.ruleList = res.data.records
      this.pageSize = res.data.size
      this.total = res.data.total
    },
    currentChange(pageNo) {
      this.queryInfo.pageNo = pageNo;
      this.search()
    },
    deleteRule(id) {
      this.$confirm('确定删除吗？', '提示', {
        cancelButtonText: '取消',
        confirmButtonText: '确定',
        type: 'warning',
      }).then(() => {
        this.$axios.post('busTipRule/deleteRule', id).then(response => {
          if (response.data.code !== 0) {
            return this.$message.error(response.data.message);
          }
          this.$message.success("删除成功");
          this.search();
        })
      })
    },
    async addRule() {
      if (!this.addInfo.materialCode && !this.addInfo.assetSn && !this.addInfo.lendOrderCode && !this.addInfo.chargeLineCode && !this.addInfo.planCategory) {
        return this.$message.error('条件不能同时为空');
      }

      this.addLoading = true
      let {data: res} = await this.$axios.post('/busTipRule/saveRule', this.addInfo);
      this.addLoading = false
      if (res.code !== 0) {
        return this.$message.error(res.message);
      }
      this.$message.success('保存成功');
      this.dialogTitle = '添加检验提示';
      this.currentChange(1)
      this.ruleDialog = false
    },
    async showChange(id) {
      let {data: res} = await this.$axios.post('/busTipRule/getRule', id);
      if (res.code !== 0) {
        this.$message.error(res.message);
      }
      this.addInfo.lendOrderCode = res.data.lendOrderCode;
      this.addInfo.materialCode = res.data.materialCode
      this.addInfo.assetSn = res.data.assetSn
      this.addInfo.ruleText = res.data.ruleText
      this.addInfo.id = res.data.id

      this.ruleDialog = true;
      this.dialogTitle = '修改检验提示';
    },
    showAdd() {
      this.ruleDialog = true
      this.dialogTitle = '添加检验提示';
      this.addInfo = {
        lendOrderCode: '',
        materialCode: '',
        assetSn: '',
        ruleText: ''
      };
    },
    exportData() {
      this.exportLoading = true;
      this.$axios.post('/busTipRule/exp', this.queryInfo).then(response => {
        this.exportLoading = false;
        let res = response.data;
        if (res.code !== 0) {
          return this.$message.error(res.message);
        }

        if (res.data.url) {
          const link = document.createElement('a');
          link.href = res.data.url;
          document.body.appendChild(link);
          link.click();
          URL.revokeObjectURL(link.href);
          document.body.removeChild(link);

        }
      }, error => {
        this.loading = false;
        return this.$message.error('导出失败，' + error.message);
      });
    },
  }
}
</script>

<style scoped>
.rule_top {
  margin: 20px 0 0 20px;
}

.rule_top-el {
  margin-right: 5px;
}

.rule_div {
  border: 1px solid #EBEEF5;
  border-radius: 5px;
}

.rule_table {
  text-align: center;
  margin-left: 20px;
  width: 95%;
  margin-bottom: 20px;
}

.form-item {
  width: 350px;
}
</style>
